import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	height: 100vh;
	display: flex;
	background: linear-gradient(#2dd1fc 0%, #27adff 100%);
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	& > div {
		flex: 1;
	}

	& > img {
		position: absolute;
		width: 20rem;
		padding: 2rem 4rem;
	}

	@media (max-width: 650px) {
		flex-direction: column;
		height: 100%;

		& > img {
			left: 20rem;
		}
	}
`;

export const LeftContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	& > img {
		width: 41rem;
		transform: translateY(15%);
	}

	.ResponsiveModelImage {
		display: none;
	}

	@media (max-width: 650px) {
		& > img {
			padding-right: 10rem;
		}

		.desktopCarousel {
			display: none;
		}

		.ResponsiveModelImage {
			display: block;
		}
	}
`;

export const RightContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 3.2rem;
	border-bottom-left-radius: 3.2rem;
	background-color: var(--white);
	z-index: 1;
	overflow-x: overlay;

	@media (max-width: 650px) {
		border-bottom-left-radius: 0rem;
		border-top-right-radius: 3.2rem;
	}
`;

export const MainContainer = styled.main`
	width: 50rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 2rem;

	& > img {
		width: 17.2rem;
	}

	& h2 {
		color: var(--dark-gray);
		font-size: 3.2rem;
		font-weight: 600;
	}

	& p {
		color: var(--dark-gray);
		font-size: 1.5rem;
	}

	@media (max-width: 650px) {
		padding: 4rem 2rem 0rem;
	}
`;

export const Header = styled.div`
	display: flex;
	row-gap: 1.5rem;
	flex-direction: column;

	& p {
		text-decoration: underline;
	}

	.progress-bar {
		height: 0.5rem;
		background-color: var(--light-gray);
		margin: 2rem 0rem 1rem;
	}

	.progress-step {
		height: 100%;
		background-color: var(--primary-blue);
		transition: width 0.3s ease;
	}

	.step-1 {
		width: 0%;
	}

	.step-2 {
		width: 50%;
	}

`;

export const Description = styled.p`
	padding: 4rem 0rem 2rem;

	@media (max-width: 650px) {
		padding: 0rem 0rem 2rem;
	}
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 1.3rem;
	margin-top: 1rem;
	margin-bottom: 1.5rem;

	.passwordWrapper {
		display: flex;
		flex-direction: column;
		gap: 1.6rem;
		align-items: right;

		@media (max-width: 650px) {
			& > a {
				margin-left: 0 !important;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}
`;

export const AccessCard = styled.div`
	display: flex;
	flex-direction: column;
	width: 40rem;

	& > img {
		width: 40rem;
		height: 35rem;
		padding: 2rem;
		object-fit: contain;
	}
	& > p {
		padding: 1rem;
		color: var(--white);
		font-size: 1.6rem;
	}
`;

export const BottomOptionsContainer = styled.div`
	display: flex;
	gap: 2rem;
	justify-content: flex-end;
	margin-top: 2rem;
	margin-bottom: 2rem;
`;

export const SupportMessage = styled.p`
	text-align: end;
	font-size: 1.2rem;

	& span {
		font-weight: 600;
	}
`