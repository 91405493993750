import ReactModal from 'react-modal';
import styled, { css, keyframes } from 'styled-components';

export type ModalPosition = 'center' | 'right' | 'left';

export const slideIn = keyframes`
  from {
    transform: translateY(-50%) translateX(0);
  }
  to {
    transform: translateY(-50%) translateX(-100%);
  }
`;

export const Modal = styled(ReactModal)<{ position: ModalPosition }>`
	background-color: rgba(255, 255, 255, 0.95);
	border-radius: ${(props) =>
		props.position === 'center'
			? '1.6rem'
			: props.position === 'right'
			? '1.6rem 0 0 1.6rem'
			: '0rem 1.6rem 1.6rem 0rem'};
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 50%;
	left: ${(props) =>
		props.position === 'center'
			? '50%'
			: props.position === 'right'
			? '100%'
			: '44rem'};
	transform: translateY(-50%)
		translateX(
			${(props) =>
				props.position === 'center'
					? '-50%'
					: props.position === 'right'
					? '-100%'
					: '-44rem'}
		);

	min-width: 20rem;
	overflow-y: auto;
	max-height: 100vh;

	${(props) =>
		props.position === 'right' &&
		css`
			animation: ${slideIn} 0.3s ease-out; // Apply the slideIn animation:;
		`}

	&::-webkit-scrollbar {
		width: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 1rem;
		background-color: #e3e8eb;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--light-gray);
		border-radius: 1rem;
	}

	.dark {
		color: var(--white);
	}

	.light {
		color: var(--dark-gray2);
	}

	@media (max-width: 500px) {
		${(props) =>
			props.position === 'center' && 'width: 100%; transform: translateY(-55%)'}
		translateX(
				${(props) =>
			props.position === 'center'
				? '-50%'
				: props.position === 'right'
				? '-100%'
				: '-44rem'}
			);
	}

	@media (max-width: 400px) {
		${(props) =>
			props.position === 'center' && 'width: 100%; transform: translateY(-50%)'}
		translateX( ${(props) =>
			props.position === 'center'
				? '-50%'
				: props.position === 'right'
				? '-100%'
				: '-44rem'}
      );
	}
`;

export const ModalTitle = styled.div`
	display: flex;
	flex: 0;
	width: 100%;
	min-height: 7.9rem;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0rem 3.2rem;
	font-size: 1.6rem;
	font-weight: 600;
	border-bottom: 1px solid #f0f3f8;
`;

export const CloseModalBtn = styled.button`
	border: 0;
	border-radius: 0.2rem;
	background: none;
	display: flex;
	align-items: center;
	width: fit-content;
	height: fit-content;
	justify-content: center;
	transition: filter 0.2s;
	&:hover {
		filter: brightness(0.9);
	}
`;

export const ModalFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 1.6rem;
	margin-bottom: 2rem;
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	padding: 2rem;
	width: 100%;
`;

export const ModalHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background: #f0f3f8;
	padding-top: 3rem;
	padding-bottom: 2rem;
`;
