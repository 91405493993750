import axios from 'axios';

const api = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
});

api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (!error.response) {
			// not able to contact the server
			return Promise.reject({
				...error,
				response: { data: { errorTypeId: -1 } },
			});
		}

		return Promise.reject(error);
	}
);

export default api;
