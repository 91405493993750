import ReactModal from 'react-modal';
import styled from 'styled-components';

export const Modal = styled(ReactModal)`
	background: var(--white);
	position: relative;
	margin: 2rem auto;
	border-radius: 1rem;
	padding: 1rem 2rem 1rem 2rem;
	min-width: 20rem;
	width: min-content;
	overflow-y: auto;
	box-shadow: 0px 3px 10px -5px rgba(54, 54, 54, 0.86);
	max-height: 90vh;
	z-index: 9999;

	&::-webkit-scrollbar {
		width: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 1rem;
		background-color: #e3e8eb;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--light-gray);
		border-radius: 1rem;
	}
`;

export const CloseModalBtn = styled.button`
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	border: 0;
	border-radius: 0.2rem;
	/* padding: 0.5rem; */
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-red);
	transition: filter 0.2s;

	&:hover {
		filter: brightness(0.9);
	}
`;
