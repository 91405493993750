export function parseUserType(userType: string){
	switch (userType) {
		case 'partner':
			return "Sócio"
		case 'proxyholder':
			return "Procurador"
		case 'legal_representative':
			return "Representante Legal"
		default:
			return userType
	}
}