import { BrowserRouter as Router, Route } from 'react-router-dom';
import PreRegister from './pages/PreRegister';
import { Redirect, Switch } from 'react-router';
import Register from './pages/Register';
import { QueryClient, QueryClientProvider } from 'react-query';

function App() {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<Switch>
					<Route path={'/pre-register'} component={PreRegister} />
					<Route path={'/register/:id'} component={Register} />
					<Redirect to='/pre-register' />
				</Switch>
			</Router>
		</QueryClientProvider>
	);
}

export default App;
