import { InputHTMLAttributes } from 'react';
import { InputComp } from './InputStyle';
import { BaseFieldProps, Field } from './Field';
import { MdErrorOutline } from 'react-icons/md';
import styled from 'styled-components';

interface Props extends InputHTMLAttributes<HTMLInputElement>, BaseFieldProps {
	errorMessage?: string;
	icon?: JSX.Element;
	onIconClick?: () => void;
}

export function InputField(props: Props) {
	if (props.register && !props.name)
		throw new Error(`If register is passed it needs the 'name' property!`);

	return (
		<Field label={props.label} name={props.name}>
		{props.icon && <IconStyle onClick={props.onIconClick}>{props.icon}</IconStyle>}
			<InputComp
				error={!!props.errorMessage}
				{...(props.register ? { ...props.register(props.name!) } : {})}
				{...props}
			/>
			{props.errorMessage && (
				<span>
					<MdErrorOutline /> {props.errorMessage}
				</span>
			)}
		</Field>
	);
}


const IconStyle = styled.span`
    cursor: pointer;
    position: absolute;
    right: 0;
	top: 0;
	bottom: 10rem;
	display:none;
	@media (max-width: 768px) {
        display: none !important;
    }
`;
