import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/ui/Button';
import { IoMdTrash } from 'react-icons/io';
import { LegalPerson, NaturalPerson, Shareholder } from '../../../@types';
import { parseUserType } from '../../../utils/parsers';
import { parseInputToDate } from '../../../utils/masks';

interface PartnerInformationsProps {
	shareholder: Shareholder;
	onEdit: () => void;
	onRemove: () => void;
}

const PartnerInformations: React.FC<PartnerInformationsProps> = ({
	shareholder,
	onEdit,
	onRemove,
}) => {
	if (shareholder.type === 'Pessoa Física') {
		const naturalPerson = shareholder as NaturalPerson;

		return (
			<Container>
				<TypePartnerBox shareholder={naturalPerson}>
					{naturalPerson.type}
				</TypePartnerBox>
				<h1>{naturalPerson.name}</h1>
				<InformationContainer>
					<TextContainer>
						<span>CPF:</span>
						<p>{naturalPerson.document}</p>
					</TextContainer>
					<TextContainer>
						<span>Nascimento:</span>
						<p>{parseInputToDate(naturalPerson.birth_date)}</p>
					</TextContainer>
					<TextContainer>
						<span>Tipo de Sócio:</span>
						<p>{parseUserType(naturalPerson.user_type)}</p>
					</TextContainer>
				</InformationContainer>
				<ButtonContainer>
					<Button
						type={"button"}
						intent='link'
						style={{
							width: '13.6rem',
							color: 'var(--primary-blue)',
							borderColor: 'var(--primary-blue)',
							margin: '0.5rem',
						}}
						onClick={onEdit}
					>
						Editar Informações
					</Button>
					<button
						type={"button"}
						onClick={onRemove}>
						<IoMdTrash size={24} />
					</button>
				</ButtonContainer>
			</Container>
		);
	} else if (shareholder.type === 'Pessoa Jurídica') {
		const company = shareholder as LegalPerson;

		return (
			<Container>
				<TypePartnerBox shareholder={company}>
					{company.type}
				</TypePartnerBox>
				<h1>{company.legal_name}</h1>
				<InformationContainer>
					<TextContainer>
						<span>CNPJ:</span>
						<p>{company.document}</p>
					</TextContainer>
					<TextContainer>
						<span>Tipo de Sócio:</span>
						<p>{parseUserType(company.type)}</p>
					</TextContainer>
				</InformationContainer>
				<ButtonContainer>
					<Button
						intent='link'
						style={{
							width: '13.6rem',
							color: 'var(--primary-blue)',
							borderColor: 'var(--primary-blue)',
							margin: '0.5rem',
						}}
						onClick={onEdit}
					>
						Editar Informações
					</Button>
					<button onClick={onRemove}>
						<IoMdTrash size={24} />
					</button>
				</ButtonContainer>
			</Container>
		);
	} else {
		return null;
	}
};

export default PartnerInformations;

const Container = styled.div`
	width: 112.8rem;
	padding: 2.4rem;
	display: flex;
	gap: 4rem;
	border: 1px solid #f0f3f8;
	align-items: center;

	& h1 {
		width: 21.5rem;
		font-size: 1.6rem;
	}

	& button {
		background: none;
	}

	@media (max-width: 1024px) {
		width: 100%;
		height: auto;
		flex-direction: column;
		align-items: flex-start;
	}
`;

const TypePartnerBox = styled.div<{ shareholder: Shareholder }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 14.5rem;
	height: 2.6rem;
	padding: 1.6rem 0.8rem;
	border-radius: 1.6rem;
	background-color: ${(props) =>
		props.shareholder.type === 'Pessoa Física'
			? 'var(--primary-blue)'
			: 'var(--primary-red)'};
	color: white;
	font-size: 1.4rem;
`;

const InformationContainer = styled.div`
	width: 40rem;
	height: 3rem;
	display: flex;
	gap: 2.4rem;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 1024px) {
		width: 100%;
		height: auto;
		align-items: normal;
		flex-direction: row;
		flex-wrap: wrap;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 3rem;
	align-items: center;

	@media (max-width: 1024px) {
		justify-content: space-between;
		width: 100%;
	}
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	& span {
		font-size: 1.2rem;
	}

	& p {
		width: auto;
		font-size: 1.4rem;
	}
`;
