import React from 'react';
import styled from 'styled-components';
import { IoMdCloseCircle } from 'react-icons/io';
import { Button } from '../../../components/ui/Button';
import { FaCheckCircle } from 'react-icons/fa';

interface Props {
	message: string | React.ReactNode;
	error?: boolean;
	onClose: () => void;
}

const StatusModal = ({ message, error, onClose }: Props) => {
	const intent = error ? 'secondary' : 'primary';

	return (
		<Container>
			<StatusContainer>
				{error ? (
					<IoMdCloseCircle color='var(--primary-red)' size={48} />
				) : (
					<FaCheckCircle color='var(--primary-blue)' size={48} />
				)}
				<p>{message}</p>
			</StatusContainer>
			<Button
				style={{ width: '16.8rem' }}
				intent={intent}
				roundness='lg'
				onClick={onClose}
			>
				Fechar
			</Button>
		</Container>
	);
};

export default StatusModal;

const Container = styled.div`
	width: 45.6rem;
	height: 26.6rem;
	padding: 3.2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 2rem;
`;

const StatusContainer = styled.div`
	width: 24.6rem;
	height: 13rem;
	gap: 2.4rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;

	& p {
		text-align: center;
		font-size: 2rem;
	}
`;
