import { Form } from '../../styles';
import { InputField } from '../../../../components/ui/Form/InputField';
import { cnpj } from 'cpf-cnpj-validator';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { SelectField } from '../../../../components/ui/Form/SelectField';
import { cnpjMask } from '../../../../utils/masks';

export const CompanyFormSchemaPreRegister = z.object({
	document: z
		.string()
		.min(18, 'Digite um CNPJ válido.')
		.refine((value) => cnpj.isValid(value), {
			message: 'Digite um CNPJ Válido.',
		}),
	legal_name: z.string().min(1, 'Razão social é obrigatório.'),
	business_type: z.string().min(1, 'Segmento comercial é obrigatório.'),
	number_of_collaborators: z
		.string()
		.min(1, 'A quantidade de funcionários é obrigatório.'),
});

type CompanyFormErrors = z.infer<typeof CompanyFormSchemaPreRegister>;

export default function CompanyForm() {
	const {
		register,
		formState: { errors },
	} = useFormContext<CompanyFormErrors>();

	const NumberEmployeesOptions = [
		{ label: '01-10', value: '01-10' },
		{ label: '11-50', value: '11-50' },
		{ label: '51-100', value: '51-100' },
		{ label: '101-200', value: '101-200' },
		{ label: '201-500', value: '201-500' },
		{ label: '501-1000', value: '501-1000' },
		{ label: 'Acima de 1000', value: '1000+' },
	];

	return (
		<Form>
			<InputField
				required
				name='legal_name'
				register={register}
				label='Razão social'
				errorMessage={
					errors.legal_name && typeof errors.legal_name.message === 'string'
						? errors.legal_name.message
						: undefined
				}
			/>
			<InputField
				required
				register={register}
				onChange={ e => {
					const {value} = e.target
					e.target.value = cnpjMask(value)
				}}
				name='document'
				label='CNPJ'
				placeholder='00.000.000/0000-00'
				errorMessage={errors.document?.message}
			/>
			<InputField
				required
				name='business_type'
				register={register}
				label='Segmento comercial'
				errorMessage={
					errors.business_type &&
					typeof errors.business_type.message === 'string'
						? errors.business_type.message
						: undefined
				}
			/>

			<SelectField
				required
				name='number_of_collaborators'
				label='Quantidade de funcionários'
				register={register}
				errorMessage={
					errors.number_of_collaborators &&
					typeof errors.number_of_collaborators.message === 'string'
						? errors.number_of_collaborators.message
						: undefined
				}
			>
				{NumberEmployeesOptions.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</SelectField>
		</Form>
	);
}
