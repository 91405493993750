import { SelectHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { BaseFieldProps, Field } from './Field';
import { BaseInputStyle } from './BaseStyle';
import { MdErrorOutline } from 'react-icons/md';
import { IoIosArrowDown } from 'react-icons/io';
import { renderToStaticMarkup as rtsm } from 'react-dom/server';

const arrowIcon = css`
	${(rtsm(IoIosArrowDown({ size: 20, color: 'black' })) as string).replace(
		/"/g,
		"'"
	)}
`;

interface Props
	extends SelectHTMLAttributes<HTMLSelectElement>,
		BaseFieldProps {
	placeholderText?: boolean;
	placeholder?: string;
}

export function SelectField({ placeholderText = true, placeholder, ...props }: Props) {
	return (
		<Field label={props.label} name={props.name}>
			<SelectInput
				{...(props.register ? { ...props.register(props.name!) } : {})}
				defaultValue=''
				{...props}
			>
				{placeholderText && (
					<option value='' disabled>
						{placeholder ??  'Selecione uma opção'}
					</option>
				)}
				{props.children}
			</SelectInput>
			{props.errorMessage && (
				<span>
					<MdErrorOutline /> {props.errorMessage}
				</span>
			)}
		</Field>
	);
}

export const SelectInput = styled.select`
	${BaseInputStyle}

	appearance: none;

	${/* // eslint-disable-next */ ''}
	background-image: url("data:image/svg+xml; utf8,${arrowIcon}");
	background-repeat: no-repeat;
	background-position: right 1.5em center;
	@media (max-width: 650px) {
			width: 100% !important;
	}
`;
