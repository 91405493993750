import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Link as ReactLink, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

type Intent = 'primary' | 'terciary';

interface Props extends LinkProps {
	$underline?: boolean;
	intent?: Intent;
	icon?: JSX.Element | boolean;
}

const getColor = (intent: Intent | undefined) => {
	if (intent === 'terciary') return 'var(--dark-gray2)';
	return 'var(--primary-blue)';
};

export function Link({ children, icon = false, ...rest }: Props) {
	const Icon = () => {
		if (icon && typeof icon === 'boolean') {
			return <MdOutlineKeyboardArrowRight />;
		} else if (icon) {
			return icon;
		}
		return null;
	};

	return (
		<LinkComp {...rest}>
			{children} {Icon()}
		</LinkComp>
	);
}

export const LinkBaseStyle = css`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.4rem;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	padding-bottom: 0.2rem;
	transition: all 0.1s ease-in-out;

	& > svg {
		width: 2rem;
		height: 2rem;
		margin-left: 0.5rem;
	}
	&:hover {
		filter: brightness(1.1);
	}
`;
const LinkComp = styled(ReactLink) <Props>`
	${LinkBaseStyle}
	border-bottom: ${(props) =>
		props.$underline ? `2px solid ${getColor(props.intent)}` : 'none'};
	color: ${(props) => getColor(props.intent)};
`;
