import styled from 'styled-components';
import registerImage from '../../assets/BackgroundImage.png';
import registerImageResponsive from '../../assets/RegisterImageResponsive.png';
import GirlControlImage from '../../assets/BackgroundImageTop.png';
import GirlControlResponsive from '../../assets/GirlControlResponsive.png';
import { HTMLAttributes } from 'react';

interface BoxProps extends HTMLAttributes<HTMLHeadingElement> {
	background: string;
	width: string;
	height: string;
}

export const Container = styled.div`
	flex: 1;
	width: 100%;
	height: 100vh;
	display: flex;

	background: url(${registerImage}) no-repeat;
	background-position: left center;
	background-size: auto 120%;

	& > img {
		position: absolute;
		width: 20rem;
		padding: 2rem 4rem;
		left: 13rem;
	}

	& > div {
		flex: 1;
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		height: 100vh;
		background: url(${registerImageResponsive}) no-repeat;
		background-size: contain;
		& > img {
			display: none;
		}
	}
`;

export const FormsContainer = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5.6rem;
		
	@media (max-width: 1024px) {
			width: 100%;
	}
`;

export const MainContainer = styled.main`
	width: 40rem;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 2rem;
	row-gap: 4rem;

	& p {
		color: var(--dark-gray);
		font-size: 1.5rem;
	}

	& h1 {
		color: var(--dark-gray);
		font-size: 2.8rem;
		font-weight: 600;
	}

		@media (max-width: 1024px) {
				width: 100%;
				padding: 3.2rem 2.4rem;
    }
`;

export const Box = styled.div<BoxProps>`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	color: white;
	background: ${(props) => props.background};
	border-radius: 1.6rem;
	padding: 4.8rem 3.2rem;
	gap: 1.6rem;

	& a {
		font-size: 1.4rem;
		color: white;
		text-decoration: underline;
	}
`;

export const LeftContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	& > div:nth-of-type(1) {
		transform: translate(18%, 49%);
		position: sticky;
	}
	& > div:nth-of-type(2) {
		z-index: 1;
		position: relative;
		transform: translate(-25%, 132%);
	}

	p {
		color: #ffffff;
		weight: 600;
		font-size: 1.8rem;
		font-family: 'Poppins', sans-serif;
	}
	@media (max-width: 1024px) {
		& > div {
			display: none;
		}

		min-height: 27rem;
	}
`;

export const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 3.2rem;
	border-bottom-left-radius: 3.2rem;
	background-color: var(--white);
	z-index: 1;
	height: 100vh;

	@media (max-width: 1024px) {
		border-bottom-left-radius: 0rem;
		border-top-right-radius: 3.2rem;

		&.thanksContainer {
			flex: 2;
		}
	}
`;

export const ScrollContainer = styled.div`
	overflow-x: overlay;
	width: 100%;
	display: flex;
	justify-content: center;
`;

export const WelcomeHeader = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.4rem;

	& h1 {
		font-size: 3.2rem;
	}

	& .responsiveText {
		display: none;
	}

	@media (max-width: 1024px) {
		gap: 3.2rem;
		align-items: center;

		& .responsiveText {
			display: block;
			font-size: 2rem;
			font-weight: 600;
			text-align: center;
			& span {
				color: var(--primary-blue);
			}
		}

		& h1 {
			font-size: 2.4rem;
		}

		& > img {
			display: none;
		}
	}
`;

export const WelcomeCheckList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.4rem;

	& h1 {
		font-size: 2rem;
	}

	& h2 {
		font-size: 1.8rem;
		color: var(--dark-gray);
		font-weight: 600;
	}

	@media (max-width: 1024px) {
		& h1 {
			font-size: 1.6rem;
		}

		width: 100%;
	}
`;

export const CheckListContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	padding: 2.4rem;
	border-radius: 8px;
	border: 1px solid #F0F3F8;

	@media (max-width: 1024px) {
		padding-left: 3rem;
	}
`;

export const ButtomOptionContainer = styled.div`
	padding-bottom: 2rem;

	& .responsiveText {
		display: none;
	}

	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;

		& .responsiveText {
			display: block;
			font-size: 1.4rem;
			color: black;
			text-decoration: underline;
		}
	}
`;

export const GeneralContainer = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;

	background: url(${GirlControlImage}) no-repeat;
	background-size: 100vw;
	& > div {
		flex: 1;
	}

	@media (max-width: 1024px) {
		background: url(${GirlControlResponsive}) no-repeat;
		background-size: contain;
	}
`;

export const TopContainer = styled.div`
	width: 100%;
	min-height: 22.6rem;

	& img {
		object-fit: cover;
		width: 100vw;
	}

	@media (max-width: 1024px) {
		min-height: 30.6rem;
	}
`;

export const BottomContainer = styled.div`
	flex: 2 !important;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	z-index: 1;
	border-top-left-radius: 3.2rem;
	border-top-right-radius: 3.2rem;
	width: 100%;
	background: var(--white);
	gap: 5.6rem;

	@media (max-width: 1024px) {
		padding: 2.4rem;
	}
`;

export const InformationContainer = styled.div`
	width: 112.8rem;
	display: flex;
	justify-content: space-between;
	padding-top: 5.6rem;
	color: var(--dark-gray);

	& > div:nth-child(1) {
		display: flex;
		flex-direction: column;
		width: 38.4rem;
		gap: 2.4rem;

		& p {
			font-size: 1.4rem;
		}
			
			@media (max-width: 1024px) {
					width: 100%;
      }
	}

	& > div:nth-child(2) {
		display: flex;
		width: 45.6rem;
		justify-content: space-between;

		@media (max-width: 1024px) {
			width: 100%;
		}

		& h3 {
			font-size: 1.4rem;
			font-weight: 400;
		}

		& p {
			font-size: 1.6rem;
			font-weight: 600;
		}

		& > div {
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
		}

		& > div:nth-child(1) {
			width: 26.4rem;

			@media (max-width: 1024px) {
				width: 50%;
			}
		}

		& > div:nth-child(2) {
			width: 16.8rem;
		}
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
		gap: 3.2rem;
		padding-top: 0;
		width: 100%;
	}
`;

export const FormContainer = styled.div`
	width: 112.8rem;
	display: flex;
	flex-wrap: wrap;
	gap: 2.4rem;

	& fieldset {
		width: auto;
	}

	@media (max-width: 1024px) {
		width: 100%;
		gap: 3.2rem;

		& fieldset {
			width: 100%;

			& .file {
				width: 100%;
			}
		}

		& .legalRepresentative {
			& fieldset {
				align-items: center;
			}
		}
	}
`;

export const BottomOptionsContainer = styled.div`
	display: flex;
	gap: 2.4rem;
	width: 112.8rem;
	justify-content: flex-end;
	margin-bottom: 4rem;

	@media (max-width: 1024px) {
		width: 100%;
		flex-direction: column-reverse;
		justify-content: center;
			align-items: center;
	}
`;

export const CorporateContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 1024px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 3.2rem;
	}
`;

export const CorporateInformation = styled.div`
    display: flex;
    flex-direction: column;
    width: 36rem;
    gap: 2.4rem;

    @media (max-width: 1024px) {
        width: 100%;
    }

    & h1 {
        font-size: 2.4rem;
    }

    & p {
        font-size: 1.4rem;
    }
`;

export const PartnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2.4rem;
	width: 100%;

	& img {
		width: 18.82rem;
		height: 16rem;
	}

	& p {
		font-size: 1.6rem;
		color: var(--dark-gray);
		width: 39.2rem;
		text-align: center;
			
		@media (max-width: 1024px) {
				width: 100%;
		}

		& span {
			color: var(--primary-blue);
			text-decoration: underline;
		}
	}

	@media (max-width: 1024px) {
		& img {
			width: 9.4rem;
			height: auto;
		}
	}
`;

export const ThanksContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3.5rem;
	width: 41.7rem;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 32.7rem;
		gap: 2.4rem;
	}

	& span {
		color: var(--primary-blue);
	}

	& p {
		width: 33.7rem;
		color: var(--dark-gray);
		font-size: 1.5rem;
	}

	& h1 {
		color: var(--dark-gray);
		font-size: 3.2rem;
		font-weight: 600;
	}

	& h3 {
		font-weight: 600;
		font-size: 2rem;
		color: var(--dark-gray);
	}
`;

export const ErrorMessage = styled.span`
	display: flex;
	align-items: center;
	color: red;
	margin-top: 0.5rem;
	width: 36rem;

	svg {
		margin-right: 0.25rem;
	}
`;

export const IconContainer = styled.div`
	display: flex;
	padding: 1rem;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
		background: var(--primary-red) ;
		border-radius: 50%;
`

export const ErrorBody = styled.div`
	width: 45.5rem;
	display: flex;
	padding: 3.2rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2.4rem;
`

export const MobileContent = styled.div`
	display: flex;
	@media (min-width: 1024px) {
		display: none;
	}
`;


export const DesktopContent = styled.div`
	display: none;
	@media (min-width: 1024px) {
			display: flex ;
  }
`;

export const PhoneCodeWrapper = styled.div<{error: boolean}>`
	position: relative;
	display: flex;
	align-items: flex-end;
	& > div {
			display: flex;
			align-items: center;
			background-color: #f0f3f8;
			font-size: 1.5rem;
			position: absolute;
			left: 1.5rem;
			top: ${({error}) => error ? '41%' : ' 55%'};

			& > span {
					color: var(--dark-gray);
					font-family: 'Poppins', sans-serif;
					font-size: 1.4rem;
			}
	}
	& > fieldset {
			& > input {
					padding-left: 5rem;
			}
	}
	@media (max-width: 1024px) {
			width: 100%;
	}
`;
