import { RegisterType } from '../../@types';
import api from '../api';

export async function createRegister(token: string, data: FormData) {
	return await api.post(`/api/v1/register-company/${token}`, data);
}

export async function getCompanyRegister(token: string) {
	const request = await api.get<RegisterType>(`/api/v1/register-company/${token}`)
	return request.data;
}

export async function saveFile(token : string, fileRequest: FormData) {
	return await api.put(`/api/v1/register-company/${token}`, fileRequest);
}
