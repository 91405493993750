import styled from 'styled-components';
import LoaderIcon from 'react-spinners/ClipLoader';
import React from 'react';

interface LoaderProps {
	color?: string;
	size?: number;
	style?: React.CSSProperties;
}

export function Loader({ color, size, style }: LoaderProps) {
	return (
		<Container style={style} data-testid='loading-container'>
			<Spinner loading={true} color={color} size={size} />
		</Container>
	);
}

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const Spinner = styled(LoaderIcon).attrs((props) => ({
	color: props.color ? props.color : 'inherit',
	size: props.size ? props.size : 50,
	loading: props.loading,
}))``;
