import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import styled from 'styled-components'

interface SuccessProps {
  title: string;
  message?: string;
  messageTime?: string
}

const Success = ( {title, message, messageTime}: SuccessProps ) => {
  return (
   <SucessContainer>
    <FaCheckCircle  />
    <h1>{title}</h1>
    <p>{message}<span>{messageTime}</span></p>
   </SucessContainer>
  )
}

export default Success

const SucessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  & > svg {
    color: var(--primary-blue);
    width: 8rem;
    height: 8rem;
  }

  & > h1 {
    font-weight: 600;
    font-size: 3.2rem;
    text-align: center;
    color: #575757;
    font-family: 'Poppins', sans-serif;
    max-width: 75%;
  }

  & > p {
    width: 70%;
    font-family: 'Poppins', sans-serif;
    margin-top: 3rem;
    text-align: center;
    font-weight: 400;
    font-size: 1.6rem;
    color: #575757;
  }

  & span {
    color: var(--primary-blue);
  }

`
