import React, { ReactNode } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import ReactModal from 'react-modal';
import * as S from './styles';
import { OTHER_MODALS_CONTAINER_ID } from '../../../contexts/DialogModalContext/index'

export interface ModalProps {
	children: ReactNode;
	isOpen: boolean;
	showClose?: boolean;
	closeBtnStyles?: React.CSSProperties;

	onRequestClose(
		event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
	): void;

	parentId?: string;
	position?: S.ModalPosition;
	headerTitle?: string | ReactNode;
	titleTheme?: string;
}

const Modal = ({
	isOpen,
	onRequestClose,
	showClose = true,
	closeBtnStyles,
	children,
	parentId: parent,
	position = 'center',
	headerTitle,
	titleTheme
}: ModalProps) => {
	// Change default Modal styles
	ReactModal.defaultStyles = {
		...ReactModal.defaultStyles,
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 99,
			backgroundColor: 'rgba(122, 122, 122, 0.685)',
		},
	};

	return (
		<S.Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			shouldCloseOnEsc={false}
			shouldCloseOnOverlayClick={true}
			ariaHideApp={false}
			parentSelector={() =>
				document.getElementById(parent ?? OTHER_MODALS_CONTAINER_ID) ??
				document.body
			}
			position={position}
			style={{
				content: {overflow:  'hidden'},
			}}
		>
			<S.ModalTitle className={titleTheme}>
				<p>{headerTitle}</p>
				{showClose && (
					<S.CloseModalBtn
						onClick={onRequestClose}
						data-testid="close-modal"
						className={titleTheme}
						style={closeBtnStyles}
					>
						<RiCloseLine size={30} />
					</S.CloseModalBtn>
				)}
			</S.ModalTitle>
			{children}
		</S.Modal>
	);
};

export default Modal;
