import React, { useRef, useState } from 'react';
import { InputHTMLAttributes } from 'react';
import { BaseFieldProps, Field } from './Field';
import FileArrows from '../../../assets/file-arrow-up-down.svg';
import styled from 'styled-components';
import { MdErrorOutline } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';

interface Props extends InputHTMLAttributes<HTMLInputElement>, BaseFieldProps {
	errorMessage?: string;
	label?: string;
	name?: string;
	textContainer: string;
	file?: string;
}

const FileField = ({
	errorMessage,
	label,
	name,
	textContainer,
	onChange,
	register,
	file,
	...rest
}: Props) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [dragging, setDragging] = useState(false);
	const [fileName, setFileName] = useState<string | null>(file || null);

	const handleButtonClick = () => {
		if (inputRef.current) {
			inputRef.current.click();
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files;
		if (file) {
			setFileName(file[0].name);
			if (onChange) {
				onChange(e);
			}
		}
	};

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault();
		setDragging(true);
	};

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault();
		setDragging(false);
	};

	const handleDrop = (e: React.DragEvent) => {
		e.preventDefault();
		setDragging(false);
		const files = e.dataTransfer.files;
		if (files.length > 0) {
			inputRef.current!.files = files;
			const event = new Event('change', { bubbles: true }) as unknown as Event;
			inputRef.current!.dispatchEvent(event);
		}
	};

	const clearFile = () => {
		setFileName(null);
		if (inputRef.current) {
			inputRef.current.value = '';
		}
	};

	return (
		<Field label={label} name={name}>
			<HiddenInput
				type='file'
				{...(register ? { ...register(name!) } : {})}
				ref={inputRef}
				onChange={handleChange}
				{...rest}
			/>
			{!fileName && (
				<Container
					{...rest}
					onClick={handleButtonClick}
					onDragOver={handleDragOver}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
					dragging={dragging}
				>
					<IconContainer>
						<img src={FileArrows} alt='' />
					</IconContainer>
					<>
						<h1>Clique ou arraste o seu arquivo</h1>
						<p>{textContainer}</p>
					</>
				</Container>
			)}
			{fileName && (
				<ContainerFile
					style={rest.style?.width ? { width: rest.style.width } : {}}
				>
					<img src={FileArrows} alt='' />
					<FileName>{fileName}</FileName>
					<ClearButton onClick={clearFile}>
						<IoMdTrash color='var(--primary-blue)' size={24} />
					</ClearButton>
				</ContainerFile>
			)}

			{errorMessage && (
				<ErrorMessage>
					<MdErrorOutline /> {errorMessage}
				</ErrorMessage>
			)}
		</Field>
	);
};

const HiddenInput = styled.input`
	position: absolute;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	z-index: -1;
`;

const IconContainer = styled.div`
	background-color: var(--primary-blue);
	border-radius: 3.2rem;
	width: 4.5rem;
	height: 4.5rem;
	display: flex;
	justify-content: center;
	align-items: center;

	& img {
		height: 3rem;
	}
`;

const Container = styled.div<{ dragging: boolean }>`
	width: 45.5rem;
	height: 22.3rem;
	background-color: #f0f3f8;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	padding: 1rem;
	transition: background-color 0.3s ease;
	color: var(--dark-gray);
	gap: 1rem;

	h1 {
		font-size: 1.4rem;
		font-weight: 600;
	}

	p {
		font-size: 1.2rem;
		max-width: 20rem;
	}

	${({ dragging }) =>
		dragging &&
		`
        background-color: var(--light-gray3);
    `}

	&:hover {
		background-color: var(--light-gray3);
	}

	&:focus {
		outline: 2px solid var(--dark-gray2);
	}
`;

export const ContainerFile = styled.div`
	width: 36rem;
	height: 5.6rem;
	background-color: #f0f3f8;
	display: flex;
	align-items: center;
    justify-content: space-between;
	padding: 1.6rem;
	gap: 1.2rem;

		& img{
           filter: brightness(0) saturate(100%) invert(61%) sepia(69%) saturate(3072%) hue-rotate(176deg) brightness(100%) contrast(102%);
        }
`;

export const FileLabelContainer = styled.div`
    display: flex;
		flex-direction: column;
		width: 39.2rem;
		margin-left: auto;
		
		@media (max-width: 1024px) {
				width: 100%;
    }
`

export const FileName = styled.p`
	font-size: 1.6rem;
	color: var(--dark-gray);
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
	height: 5.6rem;
	display: flex;
	align-items: center;
`;

export const ClearButton = styled.button`
	background: none;
	border: none;
	color: #007bff;
	cursor: pointer;
	font-size: 0.9rem;
	margin-top: 0.5rem;

	&:hover {
		text-decoration: underline;
	}
`;

const ErrorMessage = styled.span`
	display: flex;
	align-items: center;
	color: red;
	margin-top: 0.5rem;
	width: 36rem;

	svg {
		margin-right: 0.25rem;
	}
`;

export default FileField;
