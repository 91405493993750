import { Controller, useFormContext } from 'react-hook-form';
import RobotImage from '../../../../assets/RobotImage.png';
import { z } from 'zod';
import * as S from '../../styles';
import FileField, {
	ClearButton,
	ContainerFile,
	FileLabelContainer,
	FileName,
} from '../../../../components/ui/Form/FileField';
import NewPartnerModal from '../../components/NewPartnerModal';
import PartnerInformations from '../../components/PartnerInformations';
import React, { useState } from 'react';
import { Shareholder } from '../../../../@types';
import FileArrows from '../../../../assets/file-arrow-up-down.svg';
import { IoMdTrash } from 'react-icons/io';
import { MdErrorOutline } from 'react-icons/md';

export const LegalRepresentativeSchema = z.object({
	social_contract_file: z
		.instanceof(File)
		.nullable()
		.superRefine((data, ctx) => {
			if (!data) {
				ctx.addIssue({
					code: 'custom',
					message: 'O contrato social é obrigatório.',
					path: [],
				});
			} else {
				if (data.size > 3 * 1024 * 1024) {
					ctx.addIssue({
						code: 'custom',
						message: 'Arquivo muito grande, por favor carregar um arquivo com no máximo 3MB',
						path: [],
					});
				}
				if (data.type !== 'application/pdf') {
					ctx.addIssue({
						code: 'custom',
						message:
							'Arquivo em formato não aceito, por favor carregar um arquivo PDF',
						path: [],
					});
				}
			}
		}),
	shareholders: z
		.array(
			z.object({
				type: z.enum(['Pessoa Física', 'Pessoa Jurídica']),
				name: z.string().min(1).max(255).optional(),
				birth_date: z.string().optional(),
				cpf: z.string().min(11).max(14).optional(),
				partner_profile: z.string().optional(),
				legal_name: z.string().min(1).max(255).optional(),
				cnpj: z.string().min(14).max(18).optional(),
				social_contract_file: z.instanceof(File).nullable(),
			})
		)
		.min(1),
});

type LegalRepresentativeErrors = z.infer<typeof LegalRepresentativeSchema>;

export default function LegalRepresentativeForm() {
	const {
		control,
		setValue,
		watch,
		formState: { errors },
	} = useFormContext<LegalRepresentativeErrors>();

	const shareholders = watch('shareholders') as Shareholder[];
	const initialFile = watch('social_contract_file') as File | undefined;

	const [editingPartner, setEditingPartner] = useState<Shareholder | null>(
		null
	);
	const [fileName, setFileName] = useState(initialFile?.name || null);

	const addPartner = (shareholder: Shareholder) => {
		const existAlready = shareholders.find((partner) => partner.document === shareholder.document)
		if (existAlready) return false;
		setValue("shareholders", [...shareholders, shareholder]);
		return true;
	};

	const editPartner = (editedShareholder: Shareholder) => {
		const updatedPartners = shareholders.map((partner) =>
			partner === editingPartner ? editedShareholder : partner
		);
		setValue("shareholders", updatedPartners);
		setEditingPartner(null);
	};

	const removePartner = (partnerToRemove: Shareholder) => {
		const updatedPartners = shareholders.filter(
			(partner) => partner !== partnerToRemove
		);
		setValue("shareholders", updatedPartners);
	}

	const handleFileChange =
		(field: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const files = e.target.files;
			if (files && files.length > 0) {
				setFileName(files[0].name);
				field.onChange(files[0]);
			}
		};
	const clearFile = () => {
		setValue("social_contract_file", null);
		setFileName(null);
	}

	return (
		<S.FormContainer>
			<S.CorporateContainer className='legalRepresentative'>
				<S.CorporateInformation>
					<h1>1. Contrato social</h1>
					<p>
						Nesta etapa, precisaremos do documento de constituição da sua
						empresa para validação dos dados cadastrais. Se sua empresa é ONG ou
						similar, selecione o seu estatuto social.
					</p>
				</S.CorporateInformation>
				{!fileName && (
					<Controller
						name='social_contract_file'
						control={control}
						render={({ field }) => (
							<FileField
								className='file'
								accept="application/pdf"
								name={field.name}
								textContainer='Anexe o contrato social em PDF com tamanho máximo de 3MB'
								onChange={handleFileChange(field)}
								file={fileName || ''}
								errorMessage={errors?.social_contract_file?.message}
							/>
						)}
					/>
				)}

				{fileName && (
					<FileLabelContainer>
						<ContainerFile style={{width: "100%"}}>
							<img src={FileArrows} alt="" />
							<FileName>{fileName}</FileName>
							<ClearButton type={'button'} onClick={clearFile}>
								<IoMdTrash color="var(--primary-blue)" size={24} />
							</ClearButton>
						</ContainerFile>
						{
							errors.social_contract_file?.message && (
								<S.ErrorMessage>
									<MdErrorOutline /> {errors.social_contract_file?.message}
								</S.ErrorMessage>
							)
						}
					</FileLabelContainer>

				)}

			</S.CorporateContainer>
			<S.CorporateContainer>
				<S.CorporateInformation>
					<h1>2. Sócios da empresa</h1>
					<p>
						Nesta etapa, você irá cadastrar todos os sócios que fazem parte da
						sua empresa, sejam eles pessoas física ou jurídica.
					</p>
				</S.CorporateInformation>
				<NewPartnerModal
					editedPartner={editingPartner}
					onEdit={editPartner}
					onSave={addPartner}
					resetUpdatePartner={() => setEditingPartner(null)}
				/>
			</S.CorporateContainer>
			<S.PartnerContainer>
				{shareholders.length > 0 ? (
					<>
						{shareholders.map((partner, index) => (
							<PartnerInformations
								key={index}
								onEdit={() => setEditingPartner(partner)}
								onRemove={() => removePartner(partner)}
								shareholder={partner}
							/>
						))}
					</>
				) : (
					<>
						<img src={RobotImage} alt='' />
						<p>
							Você ainda não adicionou nenhum sócio, por favor clique em{' '}
							<span>adicionar novo sócio</span> acima antes de prosseguir
						</p>{' '}
					</>
				)}
			</S.PartnerContainer>
		</S.FormContainer>
	);
}
