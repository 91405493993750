import React from 'react';
import styled from 'styled-components';

interface Props {
	title: string;
	description: string;
}

const CheckListCard = ({ title, description }: Props) => {
	return (
		<Container>
			<div style={{ display: 'flex', alignItems: 'center', gap: '1.6rem' }}>
				<li>{title}</li>
			</div>
			<p>{description}</p>
		</Container>
	);
};

export default CheckListCard;

const Container = styled.div`
    gap: 1rem;
    display: flex;
    flex-direction: column;
		width: 100%;

	& li {
		font-size: 1.6rem;
		color: var(--dark-gray);
		font-weight: 600;
	}

    & p {
        font-size: 1.2rem;
        color: var(--dark-gray);
    }
`;
