import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import styled from 'styled-components';
import { InputField } from '../../../../components/ui/Form/InputField';
import { emailPattern } from '../../../../utils/patterns';

export const AdminUserDataSchema = z
	.object({
		name_admin: z.string().min(1, 'O nome completo é obrigatório.'),
		email_admin: z
			.string()
			.email('Email inválido')
			.min(1, 'O e-mail é obrigatório.'),
		confirm_email_admin: z
			.string()
			.min(1, 'A confirmação do e-mail é obrigatória.'),
	})
	.refine((data) => data.email_admin === data.confirm_email_admin, {
		message: 'Os e-mails não coincidem.',
		path: ['confirm_email_admin'],
	});

export default function AdminUserData() {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<FormContainer>
			<InputField
				required
				name='name_admin'
				register={register}
				label='Nome completo do administrador:'
				placeholder='Insira nome completo do administrador'
				errorMessage={
					errors.name_admin && typeof errors.name_admin.message === 'string'
						? errors.name_admin.message
						: undefined
				}
			/>
			<InputField
				required
				register={register}
				name='email_admin'
				label='E-mail do administrador:'
				placeholder='emailempresa@email.com.br'
				pattern={emailPattern}
				data-testid='emailInput_test_id'
				errorMessage={
					errors.email_admin && typeof errors.email_admin.message === 'string'
						? errors.email_admin.message
						: undefined
				}
			/>

			<InputField
				required
				register={register}
				name='confirm_email_admin'
				label='Confirme o email do administrador'
				placeholder='emailempresa@email.com.br'
				pattern={emailPattern}
				data-testid='emailInput_test_id'
				errorMessage={
					errors.confirm_email_admin &&
					typeof errors.confirm_email_admin.message === 'string'
						? errors.confirm_email_admin.message
						: undefined
				}
			/>
		</FormContainer>
	);
}

const FormContainer = styled.div`
	width: 36rem;
	height: 25.6rem;
	display: flex;
	flex-direction: column;
	gap: 3.2rem;
		
	@media (max-width: 1024px) {
			width: 100%;
	}
`;
